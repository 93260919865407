$unselected: #353535;
$selected: #3a3a3a;
$links: #96e6b3;
$important: #96e6b3;
$background: #070300;
$primary-text: #fff;
$secondary-text: #37ca6d;

html {
  overflow: scroll;
  overflow-x: hidden;
  background-color: $background !important;
  font-size: 18px;
}

html #root {
  background-color: $background;
}

body {
  color: $primary-text;
}

a {
  color: $links;
}

a:hover {
  color: $secondary-text;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}

h1,
h2,
h3,
h4 {
  font-family: "Playfair Display";
}

.main {
  background-color: $background;
  height: 100vh;
  padding-bottom: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.display-1 {
  font-size: 84px;
}

.display-3,
.display-4 {
  color: $important;
}

.lead {
  font-family: sans-serif;
  font-weight: 400;
  font-size: 18px;
}

.impt_txt {
  color: $important;
  font-weight: 600;
}

.h5 a {
  font-size: 34px;
}

.loading-header {
  color: #fff;
  font-weight: 200;
}

.home-left {
  height: 100vh;
  padding-top: 15vh;
}

.inner-left {
  font-weight: 400;
  width: 100%;
}

.inner-left h1 {
  color: #fff;
  font-weight: 400;
  font-family: "Playfair Display";
}

.inner-left .intro-container {
  padding-bottom: 20px;
  border-bottom: 1px solid #fff;
  width: 90%;
  margin: 0;
}

.inner-left h1 span {
  color: $important;
}

.subtext {
  padding-top: 20px;
  width: 60%;
  word-wrap: break-word;
}

.subtext p {
  font-weight: 400;
  color: #fff;
}

.lead {
  color: $primary-text;
}

.loaded {
  min-height: 0;
  display: block;
  width: 100%;
  color: black;
}

.homeContain {
  background-color: $background;
}

.homeContain .row {
  flex-direction: row-reverse;
}

.home-right {
  max-height: 100vh;
  padding: 0;
}

.home-right img {
  object-fit: cover;
  height: 100vh;
  width: 100%;
  padding: 0;
  box-shadow: 4px 4px 40px -4px black;
}

.row {
  padding: 0;
  margin: 0;
}

.inline-loading {
  display: inline-block;
}

.navbar-brand {
  display: flex;
  font-size: 26px;
  margin-left: 20px;
}

.navbar-toggler {
  color: $important;
}

.navbar-hamburger span {
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgb(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}

.trost-nav {
  padding-left: 15px;
  color: $important;
}

.trost-nav:hover {
  color: rgb(228, 131, 131);
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: end;
    margin-right: 20px;
  }
}

.nav-link {
  font-size: 22px;
  margin-top: -13px;
}

.navbar-light .navbar-nav .nav-link {
  color: $primary-text;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: $links;
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgb(228, 131, 131);
}

.show {
  background-color: $important;
  border-radius: 8px;
  padding: 10px;
}

.show div a.nav-link {
  color: #000 !important;
  text-align: center;
}

.collapsing {
  text-align: center;
  background-color: $important;
  border-radius: 8px;
  padding: 10px;
}

.collapsing div a.nav-link {
  color: #000 !important;
}

.lottie1 {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.container {
  margin: 0;
  height: auto;
}

.project-container {
  min-height: 100vh;
  padding-top: 5vh;
  background-color: $background;
  width: 100%;
  margin: 0;
}

.contact-header {
  margin-top: 60px;
  margin-bottom: 30px !important;
}

.proj-row {
  margin-bottom: 40px;
  max-width: 1700px;
  margin: auto;
}

.btn-container {
  display: flex;
  justify-content: center;
}

.btn-primary {
  color: $important;
  border: 1px solid $important;
  background-color: transparent;
  width: 100%;
  margin-bottom: 20px;
}

.btn-primary:hover {
  color: #fff;
  background-color: $important;
}

.contact-row .btn {
  margin: 15px;
  margin-bottom: 60px;
  font-size: 26px;
}

.contact-row p {
  text-align: center;
  margin-bottom: 30px;
}

.form-Container {
  margin: auto;
  width: 50%;
}

@media (max-width: 670px) {
  .form-Container {
    width: 100%;
  }
}

.contact-column {
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
}

.contact-column p {
  margin: auto;
  text-align: center;
}

.contact-links {
  color: $links;
  font-weight: 800;
}

.project-header {
  font-weight: 400;
  text-align: center;
  margin-bottom: 60px;
}

.project-img {
  object-fit: contain;
  flex: 1;
  max-width: 380px;
  max-height: 380px;
  border-radius: 5px;
}

.contact-descript {
  font-weight: 400;
}

.resume-container {
  padding-top: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.resume {
  width: 900px;
  height: 88vh;
}

.btn-holders {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.cta-btn {
  width: 200px;
  color: $important;
  border: 1px solid $important;
}

.cta-btn:hover {
  color: #000;
  background-color: $important;
}

.skill-container {
  padding-top: 5vh;
  background-color: $background;
  width: 100%;
  margin: 0;
}

.skill-header {
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
}

.skill-rows {
  display: flex;
}

.skill-svg {
  height: 90%;
  width: 90%;
}

.highlighted {
  color: $important;
}

.footer {
  height: 60px;
  background: transparent;
  width: 100%;
  display: inline-block;
}

.fab-container {
  width: 50%;
  justify-content: center;
  margin: auto;
  text-align: center;
}

.fab {
  color: $links;
  font-size: 28px;
  padding: 10px;
}

// footer buttons

.about-container {
  margin-top: 10vh;
  font-weight: 400;
  min-height: 90vh;
}

.about-header {
  font-weight: 400;
  text-align: center;
  padding-bottom: 8px;
  margin-bottom: 12px;
  border-bottom: 1px solid black;
}

.bolded {
  font-weight: 400;
}

.descript-col {
  margin-top: 40px;
  padding-right: 90px;
}

.proj-img {
  height: 280px;
  object-fit: cover;
}

.proj-col {
  margin: auto;
  background: transparent;
  padding: 0 10px;
}

.proj-img-wrap:hover {
  cursor: pointer;
}

.card-body {
  min-height: 260px;
}

.proj-col:after {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
}

.proj-col p {
  color: white;
  font-weight: 400;
  font-size: 14px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.proj-col h3 a {
  color: #fff;
  font-weight: 400;
  font-family: "Playfair Display";
}

@media (max-width: 1076px) {
  .card-columns {
    column-count: 2;
  }
}

@media (max-width: 576px) {
  .card-columns {
    column-count: 1;
  }
}

.proj-title {
  text-align: center;
  margin-top: -10px;
  margin-bottom: 25px;
}

.proj-title a {
  color: $unselected;
  text-decoration: none;
  font-weight: 400;
  padding-bottom: 2px;
  border-bottom: 1px solid $unselected;
}

.proj-title a:hover,
.proj-col:hover .card-title a {
  transition: border-color 0.8s ease;
  border-bottom: 1px solid #fff;
}

.proj-col h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 42px;
  width: 100%;
  text-align: center;
}

/* Resume Section */
.resume-contain h3 {
  color: $important;
  margin-top: 20px;
  font-weight: 800;
}

.resume-contain {
  padding-top: 20px;
}

.resume-skills {
  display: none;
}

.resume-contain .header {
  padding: 15px;
}

.resume-contain .header h2 {
  margin-bottom: 26px;
}

.resume-contain p {
  margin: 10px 0;
}

.resume-freelance {
  margin-top: 20px;
}

@media only screen and (max-width: 500px) {
  .navbar-light .navbar-nav .nav-link {
    color: $primary-text;
  }
  .contact-header {
    font-size: 34px;
  }
  .proj-col {
    max-width: 95%;
    min-height: 0;
    margin: auto;
  }

  .projects-cols {
    max-width: 100%;
  }

  .proj-img {
    height: 200px;
  }
  .project-header {
    margin-bottom: 20px;
    margin-top: 50px;
  }
  .descript-col p {
    text-align: center;
    margin: auto;
    padding: 0 10px;
  }
  .descript-col {
    padding: 0;
    margin-bottom: 50px;
  }
  .display-4 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1080px) {
  .home-right {
    display: none;
  }
  .display-1 {
    font-size: 46px;
    text-align: center;
  }
  .inner-left .intro-container {
    margin: auto;
  }
  .sub-intro {
    font-size: 38px;
  }
  .subtext {
    margin: auto;
    text-align: left;
    width: 80%;
  }
  .display-3 {
    font-size: 40px;
  }
  .display-4 {
    font-size: 34px;
    text-align: center;
    font-weight: 400;
  }
  .col-md-3 {
    width: 60%;
    margin: auto;
  }
  .skill-svg {
    margin: auto;
    justify-content: center;
    display: flex;
  }

  .skill-rows {
    padding-bottom: 30px;
  }
  .email-btn {
    text-align: center;
    margin: auto;
  }
  .inner-left h1 {
    margin: auto;
    text-align: center;
  }
  .resume {
    max-width: 100%;
    margin-top: 50px;
  }
}
