body {
  margin: 0;
  font-family: 'Playfair Display', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.proj-img-wrap {
  position: relative;
}

.proj-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
}

.proj-overlay:hover {
  visibility: visible;
}

.footer-btn:hover {
  animation: footer-animate;
}
@keyframes footer-animate {
  from {transform: translate(0,0);}
  to {
    transform: rotate(5deg); 
    transform: scale(1.01);
    transform: translate(0, 2px);
  }
}